<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterRequests"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openRequest"
            fixed-header
        >
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="idToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="usersToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.deliveryLocation`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="locationToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.dueDate`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dueDateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.items`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="itemsToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="notesToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="my-0">
                    <div>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.createdBy.name }}
                                </p>
                            </template>
                            <span>Creator</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.projectManager">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.projectManager.name }}
                                </p>
                            </template>
                            <span>Project Manager</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.buyer">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ getUser(item.buyer) }}
                                </p>
                            </template>
                            <span>Buyer</span>
                        </v-tooltip>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <p class="my-0 text-capitalize">
                    {{ formatDate(item.createdOn.seconds) }}
                </p>
            </template>
            <template v-slot:[`item.projectManager`]="{ item }">
                <p class="my-0 text-capitalize">
                    {{ item.projectManager.name }}
                </p>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <p class="my-0 text-capitalize">{{ item.project.name }}</p>
            </template>
            <template v-slot:[`item.items`]="{ item }">
                <div>
                    <p
                        class="my-0"
                        v-for="(element, index) of item.items"
                        :key="index"
                    >
                        {{ element.description }} ({{ element.quantity }})
                    </p>
                </div>
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ formatNumber(calculateTotal(item)) }}</p>
                </div>
            </template>
        </v-data-table>
        <!--ALERT MESSAGES-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Request Form-->
        <v-dialog persistent fullscreen v-model="openForm">
            <PurchaseRequest
                v-if="openForm"
                :request="selectedRequest"
                :originalRequest="originalRequest"
                :createForm="createForm"
                :step="step"
                @closeDialog="closeRequestForm"
                @replaceRequest="replaceRequest"
                @approveForPurchase="approveForPurchase"
                @rejectRequest="rejectRequest"
            />
        </v-dialog>
    </div>
</template>

<script>
import PurchaseRequest from '@/components/Purchasing/PurchaseRequest.vue'
import { firestore } from '@/services/firebase'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import moment from 'moment'
import API from '@/services/api'

export default {
    name: 'PurchasingApproval',
    components: {
        PurchaseRequest,
    },
    data: () => ({
        step: 'approval',
        createForm: false,
        selectedRequest: {},
        originalRequest: {},
        openForm: false,
        activateAlert: false,
        alertMessage: '',
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'number',
                sortable: false,
                align: 'center',
                width: '100px',
            },
            {
                text: 'PROJECT',
                value: 'project',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEMS',
                value: 'items',
                sortable: false,
                align: 'center',
            },
            {
                text: 'TOTAL PRICE',
                value: 'totalPrice',
                sortable: false,
                align: 'center',
            },
            {
                text: 'USERS',
                value: 'createdBy',
                sortable: false,
                align: 'center',
            },
            {
                text: 'REQ DATE',
                value: 'date',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DUE DATE',
                value: 'dueDate',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DELIVERY AT',
                value: 'deliveryLocation',
                sortable: false,
                align: 'center',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'left',
            },
        ],
        requests: [],
        usersToFilter: undefined,
        projectToFilter: undefined,
        itemsToFilter: undefined,
        dateToFilter: undefined,
        dueDateToFilter: undefined,
        notesToFilter: undefined,
        locationToFilter: undefined,
        deliveryParamToFilter: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        userId: JSON.parse(localStorage.getItem('userId')),
        listener: null,
        idToFilter: undefined,
        resourceId: undefined,
    }),
    computed: {
        ...mapState(['notificationResource']),
        filterRequests() {
            this.openResource()
            let conditions = []

            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }

            if (this.projectToFilter) {
                conditions.push(this.filterForProject)
            }

            if (this.deliveryParamToFilter) {
                conditions.push(this.filterForDeliveryAt)
            }

            if (this.idToFilter) {
                conditions.push(this.filterForId)
            }

            if (this.itemsToFilter) {
                conditions.push(this.filterForItems)
            }

            if (this.dateToFilter) {
                conditions.push(this.filterForDate)
            }

            if (this.dueDateToFilter) {
                conditions.push(this.filterForDueDate)
            }

            if (this.locationToFilter) {
                conditions.push(this.filterForLocation)
            }

            if (this.notesToFilter) {
                conditions.push(this.filterForNotes)
            }

            if (conditions.length > 0) {
                return this.requests.filter(request => {
                    return conditions.every(condition => {
                        return condition(request)
                    })
                })
            }
            this.sort()
            return this.requests
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            const {
                data: { user },
            } = await API.getMyInfo()

            let PurchasesQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'approval')

            if (!user.permissions.includes('allPurchases')) {
                PurchasesQuery = PurchasesQuery.where(
                    'projectManager.id',
                    '==',
                    this.userId
                )
            }

            this.listener = await PurchasesQuery.onSnapshot(
                async docSnapshot => {
                    docSnapshot.docChanges().forEach(async change => {
                        const request = Object.assign(change.doc.data(), {
                            id: change.doc.id,
                        })
                        if (change.type === 'added') {
                            request.dueDate = this.formatDate(
                                request.dueDate.seconds
                            )
                            this.requests.push(request)
                        }
                        if (change.type === 'modified') {
                            request.dueDate = this.formatDate(
                                request.dueDate.seconds
                            )
                            const index = this.requests.findIndex(
                                r => r.id == request.id
                            )
                            if (index >= 0) {
                                this.requests.splice(index, 1, request)
                            }
                        }
                        if (change.type === 'removed') {
                            const index = this.requests.findIndex(
                                r => r.id == request.id
                            )
                            if (index >= 0) {
                                this.requests.splice(index, 1)
                            }
                        }
                    })
                }
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        rejectRequest() {
            this.alertMessage = 'Purchase was rejected'
            this.activateAlert = true
            this.closeRequestForm()
        },
        filterForId(item) {
            return item.number.toString().includes(this.idToFilter)
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        approveForPurchase() {
            this.alertMessage = 'Purchase was successfully Approved'
            this.activateAlert = true
            this.openForm = false
        },
        sendToApproval() {
            this.alertMessage = 'Purchase was successfully sent to Approval'
            this.activateAlert = true
            this.openForm = false
        },
        filterForDeliveryAt(item) {
            return (
                item.deliveryAt &&
                item.deliveryAt
                    .toLowerCase()
                    .includes(this.deliveryParamToFilter.toLowerCase())
            )
        },
        filterForProject(item) {
            return (
                item.project &&
                item.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterForUsers(item) {
            return (
                (item.createdBy.name &&
                    item.createdBy.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.projectManager.name &&
                    item.projectManager.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.buyer &&
                this.users.find(user => user.id == item.buyer) != undefined
                    ? this.users
                          .find(user => user.id == item.buyer)
                          .name.toLowerCase()
                          .includes(this.usersToFilter.toLowerCase())
                    : false)
            )
        },

        filterForItems(item) {
            return item.items.some(
                i =>
                    i.description &&
                    i.description
                        .toLowerCase()
                        .includes(this.itemsToFilter.toLowerCase())
            )
        },
        filterForDate(item) {
            const date = this.formatDate(
                item.createdOn.seconds
                    ? item.createdOn.seconds
                    : item.createdOn._seconds
            )
            return date.includes(this.dateToFilter)
        },
        filterForDueDate(item) {
            return item.dueDate.includes(this.dueDateToFilter)
        },
        filterForNotes(item) {
            return (
                item.notes &&
                item.notes
                    .toLowerCase()
                    .includes(this.notesToFilter.toLowerCase())
            )
        },
        filterForLocation(item) {
            return (
                item.deliveryLocation &&
                item.deliveryLocation
                    .toLowerCase()
                    .includes(this.locationToFilter.toLowerCase())
            )
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        openRequest(request) {
            this.selectedRequest = { ...request }
            this.originalRequest = { ...request }
            this.openForm = true
        },
        closeRequestForm() {
            this.openForm = false
            this.selectedRequest = {}
            this.originalRequest = {}
        },
        replaceRequest() {},
        openResource() {
            if (this.resourceId) {
                const resource = this.requests.find(
                    r => r.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openRequest(resource)
                }
            }
        },
        sort() {
            this.requests.sort(
                (a, b) => b.createdOn.seconds - a.createdOn.seconds
            )
        },
        calculateTotal(request) {
            let calculatedTotal = 0
            request.items = request.items ? request.items : []
            request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        calculatedTotal += i.quantity * q.price
                    }
                })
            })
            return calculatedTotal
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
    position: sticky;
    z-index: 1;
}
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 6vh;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
